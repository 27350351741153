
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
  setCurrentPageToolbarType
} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { emitter } from "@/main";

export default defineComponent({
  name: "work-order-create",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  data: function() {
    const woInputData = {
      title: "",
      number: "Loading...",
      description: "",
      type: 0,
      project: 10786, //sc
      assignedTo: [],
      deadline: "",
      created: "",
      assets: [],
      procedure: 50180, //N/A
      checklists: []
    };
    const assetsList = [];
    const checkListsList = [];
    const proceduresList = [];
    const projectsList = [
      {
        id: 0,
        jobNo: {
          code: 0
        }
      }
    ];
    const employeeList = [];
    return {
      woInputData,
      assetsList,
      checkListsList,
      proceduresList,
      employeeList,
      projectsList,
      pickerOptions: {
        disabledDate: this.disabledDate
      }
    };
  },
  created: function() {
    const router = useRouter();
    setTimeout(() => {
      this.loadNextNumber();
    }, 100);
    emitter.on("saveWorkOrder", () => {
      ApiService.setHeader();
      ApiService.post("https://cmms.onecorp.cloud/api/v2/work-orders/create", {
        data: this.woInputData
      })
        .then(data => {
          console.log(data);
          if (data.data.data === "ok") {
            //added in PW, redirect to WOs
            this.woInputData = {
              title: "",
              number: "Loading...",
              description: "",
              type: 0,
              project: 10786, //sc
              assignedTo: [],
              deadline: "",
              created: "",
              assets: [],
              procedure: 50180, //N/A
              checklists: []
            };
            router.push({ name: "work-orders-list" });
          }
        })
        .catch(response => {
          console.log(response);
        });
    });
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    loadNextNumber() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/work-orders/next-number")
          .then(data => {
            this.$nextTick(() => {
              this.woInputData.number = "PS-WO-"+data.data.data;
              this.loadProjectsList();
            });
          })
          .catch(response => {
            console.log(response);
          });
    },
    loadProjectsList() {
      this.projectsList = [
        {
          id: 10786,
          jobNo: {
            code: 432
          }
        }
      ];
      this.woInputData.project = 10786; //sc
      this.loadAssetsList();
      /*ApiService.setHeader();
        ApiService.get("https://cmms.onecorp.cloud/api/v2/test/projectsListDD")
          .then(data => {
            this.$nextTick(() => {
              this.projectsList = data.data.data;
            });
          })
          .catch(response => {
            console.log(response);
          });*/
    },
    loadAssetsList() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/assets/short-list")
        .then(data => {
          this.$nextTick(() => {
            this.assetsList = data.data.data;
            this.loadProceduresList();
          });
        })
        .catch(response => {
          console.log(response);
        });
    },
    loadProceduresList() {
      ApiService.setHeader();
      ApiService.get(
        "https://cmms.onecorp.cloud/api/v2/documents/procedures/short-list"
      )
        .then(data => {
          this.$nextTick(() => {
            this.proceduresList = data.data.data;
            this.loadChecklistsList();
          });
        })
        .catch(response => {
          console.log(response);
        });
    },
    loadChecklistsList() {
      ApiService.setHeader();
      ApiService.get(
        "https://cmms.onecorp.cloud/api/v2/documents/checklists/short-list"
      )
        .then(data => {
          this.$nextTick(() => {
            this.checkListsList = data.data.data;
            this.loadEmployeeList();
          });
        })
        .catch(response => {
          console.log(response);
        });
    },
    loadEmployeeList() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/auth/users")
        .then(data => {
          this.$nextTick(() => {
            this.employeeList = data.data.data;
            //this.loadProjectsList();
          });
        })
        .catch(response => {
          console.log(response);
        });
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("New work order");
      setCurrentPageBreadcrumbs("New work order", ["Work orders"]);
      setCurrentPageToolbarType("workOrdersCreate");
    });

    return {};
  }
});
