<template>
  <div class="row createWorkOrderGrid">
    <div class="col-4">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_general_info"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/Text/Align-left.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">General information</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_general_info" class="collapse show">
          <!--begin::Form-->
          <Form
            id="kt_wo_general_info_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >Title</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="woName"
                    v-model="woInputData.title"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="WO name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="woName" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >Number</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    v-model="woInputData.number"
                    name="woNumber"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="WO number"
                    value="PS-WO-124"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="woNumber" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="d-flex flex-column mb-8">
                <label class="fs-6 fw-bold mb-2"
                  ><span class="required">Description</span></label
                >
                <el-input
                  type="textarea"
                  rows="3"
                  v-model="woInputData.description"
                  name="woDescription"
                  placeholder="Add WO description"
                />
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_type"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/General/Settings-1.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">Type</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_type" class="collapse show">
          <!--begin::Form-->
          <Form id="kt_wo_type_form" class="form" novalidate="novalidate">
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="fv-row">
                <!--begin:Options-->
                <div class="fv-row pb-2">
                  <!--begin:Option-->
                  <label class="d-flex flex-stack mb-5 cursor-pointer">
                    <!--begin:Label-->
                    <span class="d-flex align-items-center me-2">
                      <!--begin:Icon-->
                      <span class="symbol symbol-50px me-6">
                        <span class="symbol-label bg-light-primary">
                          <span class="svg-icon svg-icon-1 svg-icon-primary">
                            <inline-svg
                              src="media/icons/duotone/Tools/Pantone.svg"
                            />
                          </span>
                        </span>
                      </span>
                      <!--end:Icon-->

                      <!--begin:Info-->
                      <span class="d-flex flex-column">
                        <span class="fw-bolder fs-6"
                          >General Work Order
                          <el-tooltip
                            content="A general work order is any maintenance task that isn’t considered a preventive maintenance, inspection, emergency, or corrective maintenance task. General work orders may include tasks like setting up new equipment, dismantling old equipment or painting walls, for example."
                            placement="left"
                            popper-class="mw-300px"
                          >
                            <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                          </el-tooltip>
                        </span>
                      </span>
                      <!--end:Info-->
                    </span>
                    <!--end:Label-->

                    <!--begin:Input-->
                    <span class="form-check form-check-custom form-check-solid">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="workOrderType"
                        v-model="woInputData.type"
                        value="50230"
                      />
                    </span>
                    <!--end:Input-->
                  </label>
                  <!--end::Option-->

                  <!--begin:Option-->
                  <label class="d-flex flex-stack mb-5 cursor-pointer">
                    <!--begin:Label-->
                    <span class="d-flex align-items-center me-2">
                      <!--begin:Icon-->
                      <span class="symbol symbol-50px me-6">
                        <span class="symbol-label bg-light-success ">
                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <inline-svg
                              src="media/icons/duotone/General/Binocular.svg"
                            />
                          </span>
                        </span>
                      </span>
                      <!--end:Icon-->

                      <!--begin:Info-->
                      <span class="d-flex flex-column">
                        <span class="fw-bolder fs-6"
                          >Preventive Maintenance
                          <el-tooltip
                            content="A preventive maintenance work order is a scheduled routine maintenance task performed on individual assets or groups of assets. Preventive maintenance keeps equipment in its existing state and prevents deterioration or failure. Preventive work orders define resource requirements, instructions, checklists and notes for each task."
                            placement="left"
                            popper-class="mw-300px"
                          >
                            <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                          </el-tooltip>
                        </span>
                      </span>
                      <!--end:Info-->
                    </span>
                    <!--end:Label-->

                    <!--begin:Input-->
                    <span class="form-check form-check-custom form-check-solid">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="workOrderType"
                        v-model="woInputData.type"
                        value="50231"
                      />
                    </span>
                    <!--end:Input-->
                  </label>
                  <!--end::Option-->

                  <!--begin:Option-->
                  <label class="d-flex flex-stack mb-5 cursor-pointer">
                    <!--begin:Label-->
                    <span class="d-flex align-items-center me-2">
                      <!--begin:Icon-->
                      <span class="symbol symbol-50px me-6">
                        <span class="symbol-label bg-light-info">
                          <span class="svg-icon svg-icon-1 svg-icon-info">
                            <inline-svg
                              src="media/icons/duotone/General/Search.svg"
                            />
                          </span>
                        </span>
                      </span>
                      <!--end:Icon-->

                      <!--begin:Info-->
                      <span class="d-flex flex-column">
                        <span class="fw-bolder fs-6"
                          >Inspection
                          <el-tooltip
                            content="An inspection work order requires a maintenance technician to audit or inspect an asset based on a predetermined set of parameters. Inspections are usually time-based. During an inspection, a maintenance technician may identify a problem and then create a new work order to correct that problem."
                            placement="left"
                            popper-class="mw-300px"
                          >
                            <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                          </el-tooltip>
                        </span>
                      </span>
                      <!--end:Info-->
                    </span>
                    <!--end:Label-->

                    <!--begin:Input-->
                    <span class="form-check form-check-custom form-check-solid">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="workOrderType"
                        v-model="woInputData.type"
                        value="50232"
                      />
                    </span>
                    <!--end:Input-->
                  </label>
                  <!--end::Option-->
                  <!--begin:Option-->
                  <label class="d-flex flex-stack mb-5 cursor-pointer">
                    <!--begin:Label-->
                    <span class="d-flex align-items-center me-2">
                      <!--begin:Icon-->
                      <span class="symbol symbol-50px me-6">
                        <span class="symbol-label bg-light-danger">
                          <span class="svg-icon svg-icon-1 svg-icon-danger">
                            <inline-svg
                              src="media/icons/duotone/General/Fire.svg"
                            />
                          </span>
                        </span>
                      </span>
                      <!--end:Icon-->

                      <!--begin:Info-->
                      <span class="d-flex flex-column">
                        <span class="fw-bolder fs-6"
                          >Emergency
                          <el-tooltip
                            content="An emergency work order is generated when an unscheduled breakdown occurs and an asset needs to be repaired right away. An emergency work order is used to record and track any reactive work performed that was not planned beforehand. Once the work is complete, the maintenance technician can provide information in the work order about what happened, why the breakdown occurred, what was done about it, and what can be done to prevent it from occurring again."
                            placement="left"
                            popper-class="mw-300px"
                          >
                            <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                          </el-tooltip>
                        </span>
                      </span>
                      <!--end:Info-->
                    </span>
                    <!--end:Label-->

                    <!--begin:Input-->
                    <span class="form-check form-check-custom form-check-solid">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="workOrderType"
                        v-model="woInputData.type"
                        value="50233"
                      />
                    </span>
                    <!--end:Input-->
                  </label>
                  <!--end::Option-->
                  <!--begin:Option-->
                  <label class="d-flex flex-stack cursor-pointer">
                    <!--begin:Label-->
                    <span class="d-flex align-items-center me-2">
                      <!--begin:Icon-->
                      <span class="symbol symbol-50px me-6">
                        <span class="symbol-label bg-light-warning">
                          <span class="svg-icon svg-icon-1 svg-icon-warning">
                            <inline-svg
                              src="media/icons/duotone/Tools/Tools.svg"
                            />
                          </span>
                        </span>
                      </span>
                      <!--end:Icon-->

                      <!--begin:Info-->
                      <span class="d-flex flex-column">
                        <span class="fw-bolder fs-6"
                          >Corrective Maintenance
                          <el-tooltip
                            content="A corrective maintenance work order is generated when a maintenance technician discovers a problem while completing a preventive maintenance, inspection, general or emergency work order. Corrective maintenance is performed to identify, isolate and rectify a fault so that the equipment, machine or system can be restored to its original condition. Unlike an emergency work order, a corrective maintenance work order is planned and scheduled because the failure was identified in time. A corrective maintenance work order may consist of the repair, restoration or replacement of equipment."
                            placement="left"
                            popper-class="mw-300px"
                          >
                            <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                          </el-tooltip>
                        </span>
                      </span>
                      <!--end:Info-->
                    </span>
                    <!--end:Label-->

                    <!--begin:Input-->
                    <span class="form-check form-check-custom form-check-solid">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="workOrderType"
                        v-model="woInputData.type"
                        value="50234"
                      />
                    </span>
                    <!--end:Input-->
                  </label>
                  <!--end::Option-->
                  <ErrorMessage
                    class="fv-plugins-message-container invalid-feedback"
                    name="workOrderType"
                  />
                </div>
                <!--end:Options-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
    <div class="col-4">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_project"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/Files/Group-folders.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">Project</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_project" class="collapse show">
          <!--begin::Form-->
          <Form id="kt_wo_project_form" class="form" novalidate="novalidate">
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-8 fv-row">
                <el-select
                  v-model="woInputData.project"
                  filterable
                  default-first-option
                  placeholder="Choose project for WO"
                  class="w-100"
                >
                  <el-option
                    v-for="item in projectsList"
                    :key="item.jobNo.code"
                    :label="`PS-SQ-${item.jobNo.code}`"
                    :value="item.id"
                    >PS-SQ-{{ item.jobNo.code }}</el-option
                  >
                </el-select>
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_assigned_to"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/Communication/Group.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">Assigned to</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_assigned_to" class="collapse show">
          <!--begin::Form-->
          <Form
            id="kt_wo_assigned_to_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-8 fv-row">
                <el-select
                  v-model="woInputData.assignedTo"
                  multiple
                  filterable
                  default-first-option
                  placeholder="Choose employees for WO"
                  class="w-100"
                >
                  <el-option
                    v-for="item in employeeList"
                    :key="item"
                    :label="`${item.fullname}`"
                    :value="`${item.id}`"
                    >{{ item.fullname }}</el-option
                  >
                </el-select>
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_timing"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/Code/Time-schedule.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">Deadline</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_timing" class="collapse show">
          <!--begin::Form-->
          <Form id="kt_wo_timing_form" class="form" novalidate="novalidate">
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-8 fv-row">
                <el-date-picker
                  class="w-100"
                  v-model="woInputData.deadline"
                  format="DD.MM.YYYY"
                  placeholder="Select a date"
                  name="dueDate"
                  :picker-options="pickerOptions"
                />
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header d-none">
        <!--begin::Card header-->
        <div
            class="card-header border-0 cursor-pointer accordion-button"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_wo_created"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                    src="media/icons/duotone/Code/Time-schedule.svg"
                    class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">Created (temp)</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_created" class="collapse show">
          <!--begin::Form-->
          <Form id="kt_wo_created_form" class="form" novalidate="novalidate">
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-8 fv-row">
                <el-date-picker
                    class="w-100"
                    v-model="woInputData.created"
                    format="DD.MM.YYYY"
                    placeholder="Select a date"
                    name="createdDate"
                    :picker-options="pickerOptions"
                />
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
    <div class="col-4">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_asset_ref"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/Devices/Server.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">Assets</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_asset_ref" class="collapse hide">
          <!--begin::Form-->
          <Form id="kt_wo_asset_ref_form" class="form" novalidate="novalidate">
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-8 fv-row">
                <el-select
                  v-model="woInputData.assets"
                  multiple
                  filterable
                  default-first-option
                  placeholder="Choose assets for WO"
                  class="w-100"
                >
                  <el-option
                    v-for="item in assetsList"
                    :key="item.id"
                    :label="`${item.title} (${item.sn})`"
                    :value="item.id"
                    >{{ item.title }} ({{ item.sn }})</el-option
                  >
                </el-select>
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button collapsed"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_procedure_ref"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/Text/Align-justify.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">Procedure</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_procedure_ref" class="collapse hide">
          <!--begin::Form-->
          <Form
            id="kt_wo_procedure_ref_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-8 fv-row">
                <el-select
                  v-model="woInputData.procedure"
                  filterable
                  default-first-option
                  placeholder="Choose procedures for WO"
                  class="w-100"
                >
                  <el-option
                    v-for="item in proceduresList"
                    :key="item.mainTitle"
                    :label="`${item.mainTitle} (Rev. ${item.rev})`"
                    :value="item.id"
                    >{{ item.mainTitle }} (Rev. {{ item.rev }})</el-option
                  >
                </el-select>
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button collapsed"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_checklist_ref"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/Text/Bullet-list.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">Checklists</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_checklist_ref" class="collapse hide">
          <!--begin::Form-->
          <Form
            id="kt_wo_checklist_ref_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-8 fv-row">
                <el-select
                  v-model="woInputData.checklists"
                  multiple
                  filterable
                  default-first-option
                  placeholder="Choose checklists for WO"
                  class="w-100"
                >
                  <el-option
                    v-for="item in checkListsList"
                    :key="item.mainTitle"
                    :label="`${item.mainTitle} (Rev. ${item.rev})`"
                    :value="item.id"
                    >{{ item.mainTitle }} (Rev. {{ item.rev }})</el-option
                  >
                </el-select>
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button collapsed"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_media_files"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/Files/Pictures1.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">Media files</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_media_files" class="collapse hide">
          <!--begin::Form-->
          <Form
            id="kt_wo_media_files_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >Media files</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8">
                  <!--begin::Image input-->
                  <div
                    class="image-input image-input-outline"
                    data-kt-image-input="true"
                    style="background-image: url(media/avatars/blank.png)"
                  >
                    <!--begin::Preview existing avatar-->
                    <div
                      class="image-input-wrapper w-125px h-125px"
                      :style="`background-image: url(media/avatars/blank.png)`"
                    ></div>
                    <!--end::Preview existing avatar-->

                    <!--begin::Label-->
                    <label
                      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Upload"
                    >
                      <i class="bi bi-pencil-fill fs-7"></i>

                      <!--begin::Inputs-->
                      <input
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                      />
                      <input type="hidden" name="avatar_remove" />
                      <!--end::Inputs-->
                    </label>
                    <!--end::Label-->

                    <!--begin::Remove-->
                    <span
                      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      @click="removeImage()"
                      title="Remove"
                    >
                      <i class="bi bi-x fs-2"></i>
                    </span>
                    <!--end::Remove-->
                  </div>
                  <!--end::Image input-->

                  <!--begin::Hint-->
                  <div class="form-text">
                    Allowed file types: png, jpg, jpeg
                  </div>
                  <!--end::Hint-->
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
  setCurrentPageToolbarType
} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { emitter } from "@/main";

export default defineComponent({
  name: "work-order-create",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  data: function() {
    const woInputData = {
      title: "",
      number: "Loading...",
      description: "",
      type: 0,
      project: 10786, //sc
      assignedTo: [],
      deadline: "",
      created: "",
      assets: [],
      procedure: 50180, //N/A
      checklists: []
    };
    const assetsList = [];
    const checkListsList = [];
    const proceduresList = [];
    const projectsList = [
      {
        id: 0,
        jobNo: {
          code: 0
        }
      }
    ];
    const employeeList = [];
    return {
      woInputData,
      assetsList,
      checkListsList,
      proceduresList,
      employeeList,
      projectsList,
      pickerOptions: {
        disabledDate: this.disabledDate
      }
    };
  },
  created: function() {
    const router = useRouter();
    setTimeout(() => {
      this.loadNextNumber();
    }, 100);
    emitter.on("saveWorkOrder", () => {
      ApiService.setHeader();
      ApiService.post("https://cmms.onecorp.cloud/api/v2/work-orders/create", {
        data: this.woInputData
      })
        .then(data => {
          console.log(data);
          if (data.data.data === "ok") {
            //added in PW, redirect to WOs
            this.woInputData = {
              title: "",
              number: "Loading...",
              description: "",
              type: 0,
              project: 10786, //sc
              assignedTo: [],
              deadline: "",
              created: "",
              assets: [],
              procedure: 50180, //N/A
              checklists: []
            };
            router.push({ name: "work-orders-list" });
          }
        })
        .catch(response => {
          console.log(response);
        });
    });
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    loadNextNumber() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/work-orders/next-number")
          .then(data => {
            this.$nextTick(() => {
              this.woInputData.number = "PS-WO-"+data.data.data;
              this.loadProjectsList();
            });
          })
          .catch(response => {
            console.log(response);
          });
    },
    loadProjectsList() {
      this.projectsList = [
        {
          id: 10786,
          jobNo: {
            code: 432
          }
        }
      ];
      this.woInputData.project = 10786; //sc
      this.loadAssetsList();
      /*ApiService.setHeader();
        ApiService.get("https://cmms.onecorp.cloud/api/v2/test/projectsListDD")
          .then(data => {
            this.$nextTick(() => {
              this.projectsList = data.data.data;
            });
          })
          .catch(response => {
            console.log(response);
          });*/
    },
    loadAssetsList() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/assets/short-list")
        .then(data => {
          this.$nextTick(() => {
            this.assetsList = data.data.data;
            this.loadProceduresList();
          });
        })
        .catch(response => {
          console.log(response);
        });
    },
    loadProceduresList() {
      ApiService.setHeader();
      ApiService.get(
        "https://cmms.onecorp.cloud/api/v2/documents/procedures/short-list"
      )
        .then(data => {
          this.$nextTick(() => {
            this.proceduresList = data.data.data;
            this.loadChecklistsList();
          });
        })
        .catch(response => {
          console.log(response);
        });
    },
    loadChecklistsList() {
      ApiService.setHeader();
      ApiService.get(
        "https://cmms.onecorp.cloud/api/v2/documents/checklists/short-list"
      )
        .then(data => {
          this.$nextTick(() => {
            this.checkListsList = data.data.data;
            this.loadEmployeeList();
          });
        })
        .catch(response => {
          console.log(response);
        });
    },
    loadEmployeeList() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/auth/users")
        .then(data => {
          this.$nextTick(() => {
            this.employeeList = data.data.data;
            //this.loadProjectsList();
          });
        })
        .catch(response => {
          console.log(response);
        });
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("New work order");
      setCurrentPageBreadcrumbs("New work order", ["Work orders"]);
      setCurrentPageToolbarType("workOrdersCreate");
    });

    return {};
  }
});
</script>
